@import url(https://fonts.googleapis.com/css?family=Lato:300);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
    font-family: "NotoSansKR-Black";
    src: url(/static/media/NotoSansKR-Black.c25cdc16.otf) format("truetype");
}

@font-face {
    font-family: "NotoSansKR-Bold";
    src: url(/static/media/NotoSansKR-Bold.bbb4ca5d.otf) format("truetype");
}

@font-face {
    font-family: "NotoSansKR-DemiLight";
    src: url(/static/media/NotoSansKR-DemiLight.5e4e2d29.otf) format("truetype");
}

@font-face {
    font-family: "NotoSansKR-Light";
    src: url(/static/media/NotoSansKR-Light.7d4ce2f6.otf) format("truetype");
}

@font-face {
    font-family: "NotoSansKR-Medium";
    src: url(/static/media/NotoSansKR-Medium.7def03a7.otf) format("truetype");
}

@font-face {
    font-family: "NotoSansKR-Regular";
    src: url(/static/media/NotoSansKR-Regular.bf54b090.otf) format("truetype");
}

@font-face {
    font-family: "NotoSansKR-Thin";
    src: url(/static/media/NotoSansKR-Thin.c0b38da1.otf) format("truetype");
}

iframe {
    display: none;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-link {
    color: #61dafb;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

section {
    /* margin: 20px; */
    height: 100%;
}

.div-wrapper {
    display: block;
    width: 100%;
    text-align: center;
}

.div-wrapper-copyright {
    display: inline-block;
    width: 33%;
}
.wrap {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    flex-flow: column;
}
@media (min-width: 0px) and (max-width: 1920px) {
    .wrap {
        width: 1920px;
        padding-left: 40px;
        padding-right: 40px;
        display: flex;
        flex-flow: column;
    }
}

#datePickerTwoDiv .form-control {
    margin: 0px !important;
}

hr {
    margin-top: -10px !important;
}

section {
    flex: 1 1;
    min-height: 78vh;
    width: 100%;
}

footer {
    color: rgb(180, 180, 180);
    /* position: absolute; */
    padding: 0.1rem;
    text-align: center;
    flex: 0 1;
}

footer .logo {
    width: 30%;
    height: 100%;
}

.brand-logo {
    width: 211px;
    min-height: 30px;
    max-height: 80px;
    flex-shrink: 0;
    margin-bottom: 20px;
}

.brand-title {
    margin-left: 30px;
    letter-spacing: 0.15em;
    font-weight: bolder;
    font-family: "굴림", 돋움, 맑은 고딕;
}

.container-dd {
    justify-self: space-around;
    font-family: "NotoSansKR-demilight";
    font-size: 21px;
    margin-top: 10px;
    margin-left: -90px !important;
}

#nav-dropdown {
    color: rgb(228, 228, 228);
    font-weight: lighter;
    background-color: white;
}

.slider-h3 {
    color: #3498db;
    text-align: center;
}

.slick-prev:before {
    opacity: 1;
    color: black;
}
.slick-next:before {
    opacity: 1;
    color: black;
}

/* originated in User.css */

.box {
    width: 100%;
}

.content {
    float: right;
    margin-right: 2.5em;
    margin-bottom: 0.5em;
}

.btn-table {
    width: 100%;
    height: 100%;
    font-family: "NotoSansKR-DemiLight";
    background-color: rgb(204, 211, 226) !important;
    border-color: rgb(204, 211, 226) !important;
    color: rgb(61, 61, 61) !important;
    vertical-align: middle !important;
}

.login-box {
    display: inline-block;
    width: 100%;
}
Table {
    text-align: center;
}

td {
    font-family: "NotoSansKR-DemiLight";
    vertical-align: middle;
}
.table-wrap {
    overflow: auto;
}
.table-wrap-button {
    width: -webkit-min-content;
    width: min-content;
}
.btn-wrapper {
    margin-top: 20px;
    float: right;
}
.page-index {
    width: 100%;
    text-align: center;
}
.page-index-wrap {
    width: 100%;
    display: inline-block;
}
.page-index-wrap-btn {
    width: 50px;
    height: 50px;
    font-family: "NotoSansKR-DemiLight";
    font-size: 13px;
    color: #666;
    border: solid 2px lightgrey !important;
    border-radius: 5px !important;
    box-shadow: none !important;
    margin: 10px 1.5px;
}

.box {
    width: 100%;
}

.button-group {
    float: right;
    margin-top: 20px;
}

.login-box {
    display: inline-block;
    width: 100%;
}

.span-box {
    color: red;
    width: 50px;
    font-size: 12px;
    margin-left: 100px;
}

.form-group {
    margin: 10px 3px;
    width: 91%;
    display: flex;
    font-family: "NotoSansKR-DemiLight";
}

.form-group > label {
    font-family: source-han-sans-korean-medium, sans-serif;
    font-size: 14px !important;
    width: 30%;
    margin: 9px 35px 0;
    margin-left: 2.5rem !important;
}

.form-control {
    display: block;
    width: 480px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
            appearance: none;
    border-radius: 0.25rem;
    margin-right: 30px;
}

.form-control:focus {
    box-shadow: none !important;
}

/* added */

/* line underneath Modal title */
.headerline {
    width: 530px;
    height: 0;
    margin: 17px 62px 0;
    border: solid 0.5px #000;
}

.headerline-long {
    width: 510px;
    height: 0;
    margin: 20px 55px 3px;
    border: solid 0.5px #000;
}

.headerline-long-weightinfo {
    width: 1400px;
    height: 0;
    margin-left: 50px;
    border: solid 0.5px #000;
}

.headerline-long-add {
    width: 731px;
    height: 0;
    margin-left: 35px;
    border: solid 0.5px #000;
}

.headerline-lg {
    width: 690px;
    margin: 20px 55px 3px;
    border: solid 0.5px #000;
}

/* selector */
.selector-single {
    width: 480px;
    margin: 2px 0;
    font-family: "NotoSansKR-DemiLight";
    font-size: 15px;
    margin-bottom: 10px !important;
    margin-right: 30px;
}

.selector-single2 {
    width: 540px;
    margin: 2px 0;
    font-family: "NotoSansKR-DemiLight";
    font-size: 15px;
    margin-bottom: 10px !important;
    margin-right: 30px;
}

.selector-double {
    width: 210px;
    margin: 2px 0 10px;
    font-family: "NotoSansKR-DemiLight";
    font-size: 15px;
    margin-right: 31px;
}

.selector-double2 {
    width: 250px;
    margin: 2px 0 10px;
    font-family: "NotoSansKR-DemiLight";
    font-size: 15px;
    margin-right: 31px;
}

/* userApply updateStatus Modal Only*/

.outbox-addUsers2 {
    width: 730px;
}
.form-group2 {
    margin: 10px 3px;
    width: 91%;
    display: flex;
    font-family: "NotoSansKR-DemiLight";
}

.form-group2 > label {
    font-family: source-han-sans-korean-medium, sans-serif;
    font-size: 14px !important;
    width: 30%;
    margin: 9px 35px 0;
    margin-left: 2.5rem !important;
}
.outbox-limit2 {
    min-width: 730px;
}

.outbox-limit3 {
    min-width: 800px;
}

.headerline-long2 {
    width: 610px;
    height: 0;
    margin: 20px 55px 3px;
    border: solid 0.5px #000;
}

.headerline-long3 {
    width: 674px;
    height: 0;
    margin: 20px 55px 3px;
    border: solid 0.5px #000;
}
.selector-single3 {
    width: 900px;
    margin: 2px 0;
    font-family: "NotoSansKR-DemiLight";
    font-size: 13px;
    margin-bottom: 10px !important;
    margin-right: 20px;
}

.selector-triple {
    width: 280px;
    margin: 2px 0 10px;
    font-family: "NotoSansKR-DemiLight";
    font-size: 13px;
    margin-right: 20px;
}

/* asterisk - if it is a required field */
.required::after {
    content: " *";
    color: red;
}

/* Modal outbox */
.outbox-limit {
    min-width: 630px;
}

.outbox-limit-weightinfo {
    min-width: 1500px;
    height: auto;
    min-height: 100%;
}

.outbox-weightinfo {
    width: 1500px;
}
.outbox-addUsers {
    width: 630px;
}

.outbox-limit-add {
    min-width: 800px;
    height: auto;
    min-height: 100%;
}

.outbox-add {
    width: 800px;
}

/* button */
.btn-save {
    width: 80px;
    height: 40px;
    margin: 20px -250px 70px 0;
    border-radius: 1.5px !important;
    color: #fff;
    background-color: #aa182c !important;
    border-color: #aa182c !important;
    font-family: source-han-sans-korean, sans-serif !important;
    font-size: small !important;
    box-shadow: none !important;
}

.btn-cancel {
    width: 80px;
    height: 40px;
    margin: 20px 210px 70px 260px;
    border-radius: 1.5px !important;
    border: solid 1px #666 !important;
    background-color: #fff !important;
    color: #000 !important;
    font-family: source-han-sans-korean, sans-serif !important;
    font-size: small !important;
    box-shadow: none !important;
}

.btn-positive {
    width: 80px;
    height: 40px;
    margin: 0 210px 20px;
    border-radius: 1.5px !important;
    color: #fff;
    background-color: #aa182c !important;
    border-color: #aa182c !important;
    font-family: source-han-sans-korean, sans-serif !important;
    font-size: small !important;
    box-shadow: none !important;
}

.btn-negative {
    width: 80px;
    height: 40px;
    margin: 0 -200px 20px;
    border-radius: 1.5px !important;
    border: solid 1px #666 !important;
    background-color: #fff !important;
    color: #000 !important;
    font-family: source-han-sans-korean, sans-serif !important;
    font-size: small !important;
    box-shadow: none !important;
}

.btn-solo {
    width: 80px;
    height: 40px;
    margin: 20px 0 30px;
    border-radius: 1.5px !important;
    color: #fff;
    background-color: #aa182c !important;
    border-color: #aa182c !important;
    font-family: source-han-sans-korean, sans-serif !important;
    font-size: small !important;
    box-shadow: none !important;
}

.btn-resetPW {
    width: 130px;
    height: 40px;
    margin: 20px 10px 70px 0;
    border-radius: 1.5px !important;
    color: #fff;
    background-color: #aa182c !important;
    border-color: #aa182c !important;
    font-family: source-han-sans-korean, sans-serif !important;
    font-size: small !important;
    box-shadow: none !important;
}

input[type="radio"]:checked:after {
    width: 16px;
    height: 16px;
    border-radius: 15px;
    top: -3px;
    left: -1px;
    position: relative;
    background-color: #f2f5fa;
    content: "";
    display: inline-block;
    /* visibility: visible; */
    border: 6px solid #aa182c;
}

textarea {
    width: 100%;
    height: 90%;
    padding: 15px;
    font-family: source-han-sans-korean, sans-serif;
    margin-left: -2px;
}

/* Modal - UpdateStatus */
.txt-title {
    width: 500px;
    height: 26px;
    margin: 30px 62px 20px;
    font-family: source-han-sans-korean, sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: normal;
    color: #222;
}
.txt-title-weightinfo {
    width: 1800px;
    height: 40px;
    margin: 30px 62px 20px;
    font-family: source-han-sans-korean, sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: normal;
    color: #222;
}

.txt-title-add {
    width: 1800px;
    height: 40px;
    margin: 30px 62px 20px;
    font-family: source-han-sans-korean, sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: normal;
    color: #222;
}

.txt-updateStatus {
    font-size: 14px;
    font-family: "NotoSansKR-DemiLight";
    margin-left: 10px;
}

.txt-explanation {
    color: rgb(141, 141, 141);
    font-size: 12px;
    font-family: source-han-sans-korean, sans-serif;
    font-style: thin;
    margin-left: 15px;
    letter-spacing: -0.6px;
}

.outbox-updateStatus {
    width: 660px;
    height: 420px;
    border-radius: 2px;
    background-color: #fff;
}

.inbox-updateStatus {
    width: 530px;
    height: 180px;
    margin: 20px 45px 40px;
    padding: 29px 50px 31px 40px;
    border-radius: 5px;
    background-color: #f2f5fa;
}

::placeholder {
    font-size: 15px;
}

/* Modal - AddUser */
.expert-positive {
    margin: 13px 5px 10px -38px !important;
}

.expert-negative {
    margin: 13px 5px 10px 50px !important;
}

.txt-expert {
    margin-top: 10px;
    margin-bottom: 13px;
    font-size: 14px;
    font-weight: bold;
}

.OOTDWrite-input-file {
    margin-top: 10px;
    padding: 6px 15px;

    border-radius: 20px;
    color: black;
    cursor: pointer;
    margin-bottom: 30px;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.main {
  min-width: 100vh !important;

  min-height: 99.5vh !important;

  padding: 10% 10% 10% !important;
  background-repeat: no-repeat;
  background-size: cover;
}

.form-wrap {
  width: 480px;
  height: 529px;
  padding: 100px 80px 60px;
  box-shadow: 0 2px 80px 0 rgba(37, 38, 49, 0.05);
  background-color: #fff;
}

.form-wrap-group-1 {
  width: 320px;
  height: 40px;
  margin: 0 0 10px;
  padding: 11.1px 249px 11.9px 20px;
  border-radius: 4px;
  border: solid 1px #ccc;
  background-color: #f9f9f9;
}

.form-wrap-group-2 {
  width: 320px;
  height: 40px;
  margin: 10px 0;
  padding: 11px 238px 12px 20px;
  border-radius: 4px;
  border: solid 1px #ccc;
  background-color: #fff;
}

.Bg {
  width: 480px;
  height: 529px;
  padding: 70px 80px 60px;
  box-shadow: 0 2px 80px 0 rgba(37, 38, 49, 0.05);
  background-color: #fff;
}

.form-wrap-one {
  width: 320px;
  height: 40px;
  margin: 0 0 10px;
  padding: 11.1px 24px 11.9px 20px;
  border-radius: 4px;
  border: solid 1px #ccc;
  background-color: #f9f9f9;
  text-overflow: clip;
}

.form-wrap-two {
  width: 320px;
  height: 40px;
  margin: 10px 0px 20px;
  padding: 11px 23px 12px 20px;
  border-radius: 4px;
  border: solid 1px #ccc;
  background-color: #fff;
}

.btn-wrap-one {
  width: 320px;
  height: 50px;
  margin: 20px 0 20px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0094ca;
}

.span-wrap-findIdPw {
  width: 135px;
  height: 17px;
  margin: 10px 0 40px 3px;
  font-family: NotoSansCJKkr;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: -0.6px;
  text-align: right;
  color: #999;
  cursor: pointer;
}
.span-wrap-two {
  overflow: visible;
  cursor: pointer;
  font-family: NotoSansCJKkr;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #fff;
}
.span-wrap-applyAccount {
  width: 44px;
  height: 17px;

  font-style: bold !important;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: #777;
  cursor: pointer;
}
.span-error-box {
  font-size: 12px;
  margin: 0px;
  padding: 0px;
  font-weight: 300;
  color: red;
}
.logo {
  margin: 13.2px 0 50px 0;
}

/* main title style of pages */
.manage-title {
    width: 500px;
    height: 26px;
    margin: 5px 15px 40px;
    font-family: source-han-sans-korean, sans-serif;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: normal;
    color: #333;
}

/* button style when the buttons are three */
.btn-saveTriple {
    width: 80px;
    height: 40px;
    margin: 1px -35px 18px -100px;
    border-radius: 2px !important;
    color: #fff;
    background-color: #aa182c !important;
    border-color: #aa182c !important;
    font-family: source-han-sans-korean, sans-serif !important;
    font-size: 15px !important;
    box-shadow: none !important;
}

.btn-search {
    width: 80px;
    height: 40px;
    margin: 1px -35px 18px -40px;
    border-radius: 2px !important;
    font-family: source-han-sans-korean, sans-serif !important;
    background-color: #7d9bc8 !important;
    border-color: #7d9bc8 !important;
    font-size: 15px !important;
    box-shadow: none !important;
}

.btn-add {
    width: 83px;
    height: 40px;
    margin: 1px 50px 18px;
    border-radius: 2px !important;
    color: #fff;
    background-color: #aa182c !important;
    border-color: #aa182c !important;
    font-family: source-han-sans-korean, sans-serif !important;
    font-size: 15px !important;
    box-shadow: none !important;
}

.btn-add-long {
    width: 140px;
    height: 40px;
    margin: 1px 50px 18px;
    border-radius: 2px !important;
    color: #fff;
    background-color: #aa182c !important;
    border-color: #aa182c !important;
    font-family: source-han-sans-korean, sans-serif !important;
    font-size: 15px !important;
    box-shadow: none !important;
}

/* UserInfo */

/* status button  */
.btn-status {
    width: 90px;
    height: 60px;
    font-family: "NotoSansKR-DemiLight";
    background-color: rgb(168, 180, 206) !important;
    border-color: rgb(168, 180, 206) !important;
    color: rgb(61, 61, 61) !important;
    vertical-align: middle !important;
}

.btn-status_error {
    width: 90px;
    height: 60px;
    font-family: "NotoSansKR-DemiLight";
    background-color: #aa182c !important;
    border-color: #aa182c !important;
    color: rgb(235, 235, 235) !important;
    vertical-align: middle !important;
}

.btn-edit {
    width: 58px;
    height: 40px;
    border: none !important;
    text-decoration: underline !important;
    color: #666 !important;
    box-shadow: none !important;
    margin: 0 5px;
    cursor: pointer;
}

.btn-edit_error {
    width: 58px;
    height: 40px;
    border: none !important;
    text-decoration: underline !important;
    color: #d11f37 !important;
    box-shadow: none !important;
    margin: 0 5px;
    cursor: pointer;
}

.style-searchBox {
    width: auto;
    height: 140px;
    margin-bottom: 10px;
    background-color: #f5f5f5;
}
.style-searchBox2 {
    width: auto;
    height: auto;
    margin-bottom: 10px;
    background-color: #f5f5f5;
}

.style-searchBox_soloLine {
    width: auto;
    height: 70px;
    margin-bottom: 10px;
    background-color: #f5f5f5;
}

/* label in search box */
.label-search_trUp {
    font-family: "NotoSansKR-DemiLight";
    font-size: 15px;
    margin: 32px -20px 25px 35px;
    float: left;
}

.label-search_trDown {
    font-family: "NotoSansKR-DemiLight";
    font-size: 15px;
    margin: 10px -20px 15px 35px;
    float: left;
}

/* selector in searchbox but when it is three */
.selector-tripleUp {
    width: 450px;
    margin-right: -220px;
    font-family: "NotoSansKR-DemiLight";
    font-size: 15px;
    margin: 15px 6px 7px 30px;
}

.selector-tripleDown {
    width: 450px;
    margin-right: -220px;
    font-family: "NotoSansKR-DemiLight";
    font-size: 15px;
    margin: 5px 6px 10px 30px;
}

.selector-soloTr {
    width: 450px;
    margin-right: -220px;
    font-family: "NotoSansKR-DemiLight";
    font-size: 15px;
    margin: 18px 50px 10px 10px;
}

.selector-soloQuad {
    width: 340px;
    font-family: "NotoSansKR-DemiLight";
    font-size: 15px;
    margin: 18px 10px 10px 10px;
}

/* input box for choosing date */
.input-date1 {
    width: 212px !important;
    height: 37.5px;
    font-family: "NotoSansKR-DemiLight";
    font-size: 15px;
    text-align: center;
    color: #212529;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    margin-top: 15px;
    margin-left: 50px;
}

.input-date2 {
    width: 212px !important;
    height: 37px;
    font-family: "NotoSansKR-DemiLight";
    font-size: 15px;
    text-align: center;
    color: #212529;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    margin-top: 15px;
    margin-left: 5px;
}

.input-soloTr {
    display: block;
    width: 410px !important;
    padding: 0.375rem 0.75rem;
    margin: 14px 3px 5px 10px;
    font-size: 14px;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.label-soloTr {
    margin-top: 10px;
}

/* manage User pages */
.style-input_up {
    display: block;
    width: 440px !important;
    padding: 0.375rem 0.75rem;
    margin: 10px 3px 5px 50px;
    font-size: 14px;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.style-input_down {
    display: block;
    width: 440px !important;
    padding: 0.375rem 0.75rem;
    margin: 5px 3px 10px 50px;
    font-size: 14px;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.style-input:focus {
    border: 0.5px solid grey;
    outline: 0;
}

/* manage Sys pages*/
.btn-table_delete {
    width: 100%;
    height: 100%;
    font-family: "NotoSansKR-DemiLight";
    background-color: lightgrey !important;
    background-color: #aa182c !important;
    border-color: #aa182c !important;
    vertical-align: middle !important;
}

/* manage Org pages */
.selector-doubleTr {
    width: 400px !important;
    font-family: "NotoSansKR-DemiLight";
    font-size: 15px;
    margin: 5px -100px 10px 50px;
}

/* manage Stat pages */
.graph-stat {
    width: 90%;
    height: 90%;
    border: 1px solid rgb(100, 100, 100);
    margin-left: 40px;
    padding: 5px 10px 5px 5px;
}

.style-h4 {
    margin-left: 40px;
}

.btn-searchStat {
    width: 80px;
    height: 40px;
    margin: 16px 10px 18px -65px;
    border-radius: 2px !important;
    font-family: source-han-sans-korean, sans-serif !important;
    background-color: #7d9bc8 !important;
    border-color: #7d9bc8 !important;
    font-size: 15px !important;
    box-shadow: none !important;
}

.style-tHeader {
    background-color: #f7f9fa;
    font-family: source-han-sans-korean-medium, sans-serif;
    padding: 15px 0 !important;
    box-shadow: none !important;
    border-top: 2px solid #666;
    border-bottom: 2px solid #666;
}

.style-TheadHeader {
    border-top: 3px solid #666;
}

.style-td {
    width: 250px;
    height: 40px;
    padding-top: 15px;
    vertical-align: top;
}

.style-condition {
    width: 250px;
    height: 72px !important;
    margin-left: 20px;
    margin-bottom: -10px !important;
    /* background-color: #eee; */
}

/* statReason AI label*/
.labe-AI {
    width: 250px;
    height: 72px;
    margin-bottom: -10px;
}

/* menuInfo - inputBox which can be edited */
.input-flexible {
    width: 100%;
    height: 40px;
    display: block;
    font-size: 14px !important;
    color: #212529;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    text-align: center;
}
input.input-flexible::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 1; /* Firefox */
}

/* manage bulletin pages */
.form-wrap-bulletinContent {
    margin-left: 5%;
    width: 1700px !important;
    height: -webkit-max-content;
    height: max-content;
    margin-top: 40px;
}

@media print {
    canvas.chart-canvas {
        min-height: 100%;
        max-width: 100%;
        max-height: 100%;
        height: auto !important;
        width: auto !important;
    }
}

@page {
    size: auto;
    margin: 15mm;
}

:root {
    /* Color  */
    --darkgray-color: #282828;
    --orange-color: #f59a23;
    --yellow-color: #fac700;
    --skyblue-color: #58b1ff;
    --lightpurple-color: #8a94ff;
    --lightblue-color: #84e9f6;
    --wantbtn-color: rgb(238, 15, 101);

    /* Size */
    --movietitle-padding: 8px;
    --infoside-padding: 12px;
    --star-size: 25px;
    --movieline-margin: 470px;
    --movieclass-margin: 300px;

    /* font */
    --font-title: 30px;
    --font-large: 18px;
    --font-lmedium: 16px;
    --font-medium: 15px;
    --font-small: 14px;
}

/* Loading Bar  css */

.loading-container {
    border: 0px;
    background: rgba(0, 0, 0, 0);
    z-index: -1;
    content: none;
}
.loading-container > div.modal-content {
    border: 0px;
}
.overlay-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    background: rgba(255, 255, 255, 0);

    z-index: 1000;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.headline-signUp {
    width: 540px;
    height: 0;
    margin: 20px 40px 20px 35px;
    border: solid 0.5px #000;
}

.form-wrap-signUp {
    margin-left: 33%;
    width: 630px !important;
    height: -webkit-max-content;
    height: max-content;
    margin-top: 40px;
    border-style: ridge;
    padding-bottom: 10px;
}

.div-wrap-btn {
    width: 550px;
    display: inline-block;
    margin-top: 20px;
    margin-left: 15px;
}

.btn-register {
    width: 260px;
    height: 50px;
    background-color: #aa182c !important;
    border-color: #aa182c !important;
    color: white;
    margin-left: 10px;
}

.btn-registerNo {
    width: 260px;
    height: 50px;
    margin-left: 10px;
    border: solid 1px grey !important;
    background-color: grey !important;
    color: #fff !important;
}

.div-wrap-policy {
    text-align: center;
    margin-top: 10px;
}

.btn-policy {
    font-family: "NotoSansKR-Demilight";
    font-size: 14px;
    margin-bottom: 4px;
    box-shadow: none !important;
    outline: none !important;
    float: right;
}

.txt-policy {
    color: black;
    font-family: "NotoSansKR-Bold";
    font-size: 15px;
    margin-left: 5px;
    text-align: right;
}

.style-ul {
    margin-left: 20px;
}

.style-li {
    margin-bottom: 18px;
}

.form-wrap {
    margin-left: 33%;
    width: 33%;
    margin-top: 1%;
    border: 2.5px solid gray;
    border-radius: 5px;
    padding: 30px;
}

.form-group {
    width: 560px;
    margin-left: 10px;
}

.form-group > label {
    margin-right: 20px;
}

.form-control {
    width: 550px;
    margin-top: 5px;
    margin-bottom: 15px;
    margin-left: 7px;
}

.selector-single {
    margin-left: 4px;
}
.selector-single2 {
    margin-left: 4px;
}

.form-group-wrap {
    text-align: left;
}
.form-group-wrap-content-text {
    width: 75%;
    display: inline-block;
    margin-right: 5%;
}
.form-group-wrap-content-text > span {
    width: 75%;
    display: inline-block;
    text-align: center;
    margin-right: 5%;
}

.form-group-wrap-content-btn {
    width: 20%;
    display: inline-block;
}
.btn-login-check {
    padding-top: 20px;
    margin-top: 2%;
    float: right;
}

/* 

// policy buttons 

.btn-useTerm {
  width: 15%;
  margin: 0 2%;
}

.btn-copyright {
  width: 33%;
  margin: 0 2%;
}

.btn-perinfo {
  width: 10%;
  margin: 0 2%;
}

*/

* {
    padding: 0;
    margin: 0;
}
.contentBox {
    border-style: ridge;
    padding-bottom: 10px;
}
.contentBox-TextArea {
    width: 100%;
    height: auto;
    padding: 10px;
    resize: none;
    border: solid 1px black;
    border-radius: 5px;
    font-size: 16px;
}
.btn-save {
    width: 80px;
    height: 40px;
    margin: 20px -250px 70px 0;
    border-radius: 1.5px !important;
    color: #fff;
    background-color: #aa182c !important;
    border-color: #aa182c !important;
    font-family: source-han-sans-korean, sans-serif !important;
    font-size: small !important;
    box-shadow: none !important;
}

.btn-cancel {
    width: 80px;
    height: 40px;
    margin: -50px 18px 0px 260px;
    border-radius: 1.5px !important;
    border: solid 1px #666 !important;
    background-color: #fff !important;
    color: #000 !important;
    font-family: source-han-sans-korean, sans-serif !important;
    font-size: small !important;
    box-shadow: none !important;
}

.btn-cancel-c1 {
    width: 80px;
    height: 40px;
    margin: -50px 18px 0px 260px;
    border-radius: 1.5px !important;

    background-color: #7d9bc8 !important;
    color: #fff !important;
    font-family: source-han-sans-korean, sans-serif !important;
    font-size: small !important;
}

.btn-cancel2 {
    width: 80px;
    height: 40px;
    margin: 0px 18px 0px -10px;

    background-color: #fff !important;
    color: #000 !important;
    font-family: source-han-sans-korean, sans-serif !important;
    font-size: small !important;
}

.btn-cancel2-c1 {
    width: 80px;
    height: 40px;
    margin: 0px 18px 0px -10px;
    border: solid 1px #666 !important;
    background-color: #7d9bc8 !important;
    color: #fff !important;
    font-family: source-han-sans-korean, sans-serif !important;
    font-size: small !important;
}

.btn-cancel2-c2 {
    width: 80px;
    height: 40px;
    margin: 0px 18px 0px -10px;
    border: solid 1px #666 !important;
    background-color: #aa182c !important;
    color: #fff !important;
    font-family: source-han-sans-korean, sans-serif !important;
    font-size: small !important;
}

.btn-cancel2-c3 {
    width: 80px;
    height: 40px;
    margin: 0px 18px 0px -10px;

    border: solid 1px #666 !important;
    background-color: #fff !important;
    color: #000 !important;
    font-family: source-han-sans-korean, sans-serif !important;
    font-size: small !important;
    box-shadow: none !important;
}

.reply-listBox {
    margin: 100px 0px 0px 0px;
}

.flex {
    display: flex;
}
/* 
.scroll {
    width: 100px;
    padding: 0px 13px 0px 13px;
    overflow-y: scroll;
    height: 200px;
    box-sizing: border-box;
    color: white;
    font-family: "Nanum Gothic";
    background-color: rgba(0, 0, 0, 0.8);
    margin-right: 50px;
} */

/* 스크롤바 설정*/
.type1::-webkit-scrollbar {
    width: 6px;
}

/* 스크롤바 막대 설정*/
.type1::-webkit-scrollbar-thumb {
    height: 17%;
    background-color: rgba(255, 255, 255, 1);
    /* 스크롤바 둥글게 설정    */
    border-radius: 10px;
}

/* 스크롤바 뒷 배경 설정*/
.type1::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
}

/* 스크롤바 설정*/
.type2::-webkit-scrollbar {
    width: 6px;
}

/* 스크롤바 막대 설정*/
.type2::-webkit-scrollbar-thumb {
    height: 17%;
    background-color: rgba(33, 133, 133, 1);
    border-radius: 10px;
}

/* 스크롤바 뒷 배경 설정*/
.type2::-webkit-scrollbar-track {
    background-color: rgba(33, 133, 133, 0.33);
}

/* ::-webkit-scrollbar {
    width: 15px;
}

::-webkit-scrollbar-track {
    background: #eeeeee;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb {
    margin: 10px;
    background: #c2e59c;
    background: linear-gradient(#c2e59c, #64b3f4); 
    border-radius: 5px;
} */

.scrollDiv {
    /* width: 200px; */
    /* height: 400px; */
    /* overflow: scroll; */
    /* background-color: #1e1e1e; */
    /* color: white; */
    padding: 5px;
}

.scrollDiv::-webkit-scrollbar {
    width: 15px; /*스크롤바의 너비*/
}

.scrollDiv::-webkit-scrollbar-thumb {
    background-color: rgba(66, 66, 66, 0.3); /*스크롤바의 색상*/
    background-clip: padding-box;
    border: 4px solid transparent;
    border-radius: 10px;
    /* border-top-left-radius: 50px; */
    /* border-bottom-right-radius: 50px; */
}

.scrollDiv::-webkit-scrollbar-track {
    /*스크롤바 트랙 색상*/
    /* background-color: grey; */
}

button.accordion-button {
    padding: 10px 20px;
}

