* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.main {
  min-width: 100vh !important;

  min-height: 99.5vh !important;

  padding: 10% 10% 10% !important;
  background-repeat: no-repeat;
  background-size: cover;
}

.form-wrap {
  width: 480px;
  height: 529px;
  padding: 100px 80px 60px;
  box-shadow: 0 2px 80px 0 rgba(37, 38, 49, 0.05);
  background-color: #fff;
}

.form-wrap-group-1 {
  width: 320px;
  height: 40px;
  margin: 0 0 10px;
  padding: 11.1px 249px 11.9px 20px;
  border-radius: 4px;
  border: solid 1px #ccc;
  background-color: #f9f9f9;
}

.form-wrap-group-2 {
  width: 320px;
  height: 40px;
  margin: 10px 0;
  padding: 11px 238px 12px 20px;
  border-radius: 4px;
  border: solid 1px #ccc;
  background-color: #fff;
}

.Bg {
  width: 480px;
  height: 529px;
  padding: 70px 80px 60px;
  box-shadow: 0 2px 80px 0 rgba(37, 38, 49, 0.05);
  background-color: #fff;
}

.form-wrap-one {
  width: 320px;
  height: 40px;
  margin: 0 0 10px;
  padding: 11.1px 24px 11.9px 20px;
  border-radius: 4px;
  border: solid 1px #ccc;
  background-color: #f9f9f9;
  text-overflow: clip;
}

.form-wrap-two {
  width: 320px;
  height: 40px;
  margin: 10px 0px 20px;
  padding: 11px 23px 12px 20px;
  border-radius: 4px;
  border: solid 1px #ccc;
  background-color: #fff;
}

.btn-wrap-one {
  width: 320px;
  height: 50px;
  margin: 20px 0 20px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0094ca;
}

.span-wrap-findIdPw {
  width: 135px;
  height: 17px;
  margin: 10px 0 40px 3px;
  font-family: NotoSansCJKkr;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: -0.6px;
  text-align: right;
  color: #999;
  cursor: pointer;
}
.span-wrap-two {
  overflow: visible;
  cursor: pointer;
  font-family: NotoSansCJKkr;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #fff;
}
.span-wrap-applyAccount {
  width: 44px;
  height: 17px;

  font-style: bold !important;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: #777;
  cursor: pointer;
}
.span-error-box {
  font-size: 12px;
  margin: 0px;
  padding: 0px;
  font-weight: 300;
  color: red;
}
.logo {
  margin: 13.2px 0 50px 0;
}
