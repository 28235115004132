body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
    font-family: "NotoSansKR-Black";
    src: url("./font/NotoSansKR-Black.otf") format("truetype");
}

@font-face {
    font-family: "NotoSansKR-Bold";
    src: url("./font/NotoSansKR-Bold.otf") format("truetype");
}

@font-face {
    font-family: "NotoSansKR-DemiLight";
    src: url("./font/NotoSansKR-DemiLight.otf") format("truetype");
}

@font-face {
    font-family: "NotoSansKR-Light";
    src: url("./font/NotoSansKR-Light.otf") format("truetype");
}

@font-face {
    font-family: "NotoSansKR-Medium";
    src: url("./font/NotoSansKR-Medium.otf") format("truetype");
}

@font-face {
    font-family: "NotoSansKR-Regular";
    src: url("./font/NotoSansKR-Regular.otf") format("truetype");
}

@font-face {
    font-family: "NotoSansKR-Thin";
    src: url("./font/NotoSansKR-Thin.otf") format("truetype");
}

iframe {
    display: none;
}
