:root {
    /* Color  */
    --darkgray-color: #282828;
    --orange-color: #f59a23;
    --yellow-color: #fac700;
    --skyblue-color: #58b1ff;
    --lightpurple-color: #8a94ff;
    --lightblue-color: #84e9f6;
    --wantbtn-color: rgb(238, 15, 101);

    /* Size */
    --movietitle-padding: 8px;
    --infoside-padding: 12px;
    --star-size: 25px;
    --movieline-margin: 470px;
    --movieclass-margin: 300px;

    /* font */
    --font-title: 30px;
    --font-large: 18px;
    --font-lmedium: 16px;
    --font-medium: 15px;
    --font-small: 14px;
}

/* Loading Bar  css */
@import url(https://fonts.googleapis.com/css?family=Lato:300);

.loading-container {
    border: 0px;
    background: rgba(0, 0, 0, 0);
    z-index: -1;
    content: none;
}
.loading-container > div.modal-content {
    border: 0px;
}
.overlay-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    background: rgba(255, 255, 255, 0);

    z-index: 1000;
}
