* {
    padding: 0;
    margin: 0;
}
.contentBox {
    border-style: ridge;
    padding-bottom: 10px;
}
.contentBox-TextArea {
    width: 100%;
    height: auto;
    padding: 10px;
    resize: none;
    border: solid 1px black;
    border-radius: 5px;
    font-size: 16px;
}
.btn-save {
    width: 80px;
    height: 40px;
    margin: 20px -250px 70px 0;
    border-radius: 1.5px !important;
    color: #fff;
    background-color: #aa182c !important;
    border-color: #aa182c !important;
    font-family: source-han-sans-korean, sans-serif !important;
    font-size: small !important;
    box-shadow: none !important;
}

.btn-cancel {
    width: 80px;
    height: 40px;
    margin: -50px 18px 0px 260px;
    border-radius: 1.5px !important;
    border: solid 1px #666 !important;
    background-color: #fff !important;
    color: #000 !important;
    font-family: source-han-sans-korean, sans-serif !important;
    font-size: small !important;
    box-shadow: none !important;
}

.btn-cancel-c1 {
    width: 80px;
    height: 40px;
    margin: -50px 18px 0px 260px;
    border-radius: 1.5px !important;

    background-color: #7d9bc8 !important;
    color: #fff !important;
    font-family: source-han-sans-korean, sans-serif !important;
    font-size: small !important;
}

.btn-cancel2 {
    width: 80px;
    height: 40px;
    margin: 0px 18px 0px -10px;

    background-color: #fff !important;
    color: #000 !important;
    font-family: source-han-sans-korean, sans-serif !important;
    font-size: small !important;
}

.btn-cancel2-c1 {
    width: 80px;
    height: 40px;
    margin: 0px 18px 0px -10px;
    border: solid 1px #666 !important;
    background-color: #7d9bc8 !important;
    color: #fff !important;
    font-family: source-han-sans-korean, sans-serif !important;
    font-size: small !important;
}

.btn-cancel2-c2 {
    width: 80px;
    height: 40px;
    margin: 0px 18px 0px -10px;
    border: solid 1px #666 !important;
    background-color: #aa182c !important;
    color: #fff !important;
    font-family: source-han-sans-korean, sans-serif !important;
    font-size: small !important;
}

.btn-cancel2-c3 {
    width: 80px;
    height: 40px;
    margin: 0px 18px 0px -10px;

    border: solid 1px #666 !important;
    background-color: #fff !important;
    color: #000 !important;
    font-family: source-han-sans-korean, sans-serif !important;
    font-size: small !important;
    box-shadow: none !important;
}

.reply-listBox {
    margin: 100px 0px 0px 0px;
}
