/* originated in User.css */

.box {
    width: 100%;
}

.content {
    float: right;
    margin-right: 2.5em;
    margin-bottom: 0.5em;
}

.btn-table {
    width: 100%;
    height: 100%;
    font-family: "NotoSansKR-DemiLight";
    background-color: rgb(204, 211, 226) !important;
    border-color: rgb(204, 211, 226) !important;
    color: rgb(61, 61, 61) !important;
    vertical-align: middle !important;
}

.login-box {
    display: inline-block;
    width: 100%;
}
Table {
    text-align: center;
}

td {
    font-family: "NotoSansKR-DemiLight";
    vertical-align: middle;
}
.table-wrap {
    overflow: auto;
}
.table-wrap-button {
    width: min-content;
}
.btn-wrapper {
    margin-top: 20px;
    float: right;
}
.page-index {
    width: 100%;
    text-align: center;
}
.page-index-wrap {
    width: 100%;
    display: inline-block;
}
.page-index-wrap-btn {
    width: 50px;
    height: 50px;
    font-family: "NotoSansKR-DemiLight";
    font-size: 13px;
    color: #666;
    border: solid 2px lightgrey !important;
    border-radius: 5px !important;
    box-shadow: none !important;
    margin: 10px 1.5px;
}

.box {
    width: 100%;
}

.button-group {
    float: right;
    margin-top: 20px;
}

.login-box {
    display: inline-block;
    width: 100%;
}

.span-box {
    color: red;
    width: 50px;
    font-size: 12px;
    margin-left: 100px;
}

.form-group {
    margin: 10px 3px;
    width: 91%;
    display: flex;
    font-family: "NotoSansKR-DemiLight";
}

.form-group > label {
    font-family: source-han-sans-korean-medium, sans-serif;
    font-size: 14px !important;
    width: 30%;
    margin: 9px 35px 0;
    margin-left: 2.5rem !important;
}

.form-control {
    display: block;
    width: 480px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    margin-right: 30px;
}

.form-control:focus {
    box-shadow: none !important;
}

/* added */

/* line underneath Modal title */
.headerline {
    width: 530px;
    height: 0;
    margin: 17px 62px 0;
    border: solid 0.5px #000;
}

.headerline-long {
    width: 510px;
    height: 0;
    margin: 20px 55px 3px;
    border: solid 0.5px #000;
}

.headerline-long-weightinfo {
    width: 1400px;
    height: 0;
    margin-left: 50px;
    border: solid 0.5px #000;
}

.headerline-long-add {
    width: 731px;
    height: 0;
    margin-left: 35px;
    border: solid 0.5px #000;
}

.headerline-lg {
    width: 690px;
    margin: 20px 55px 3px;
    border: solid 0.5px #000;
}

/* selector */
.selector-single {
    width: 480px;
    margin: 2px 0;
    font-family: "NotoSansKR-DemiLight";
    font-size: 15px;
    margin-bottom: 10px !important;
    margin-right: 30px;
}

.selector-single2 {
    width: 540px;
    margin: 2px 0;
    font-family: "NotoSansKR-DemiLight";
    font-size: 15px;
    margin-bottom: 10px !important;
    margin-right: 30px;
}

.selector-double {
    width: 210px;
    margin: 2px 0 10px;
    font-family: "NotoSansKR-DemiLight";
    font-size: 15px;
    margin-right: 31px;
}

.selector-double2 {
    width: 250px;
    margin: 2px 0 10px;
    font-family: "NotoSansKR-DemiLight";
    font-size: 15px;
    margin-right: 31px;
}

/* userApply updateStatus Modal Only*/

.outbox-addUsers2 {
    width: 730px;
}
.form-group2 {
    margin: 10px 3px;
    width: 91%;
    display: flex;
    font-family: "NotoSansKR-DemiLight";
}

.form-group2 > label {
    font-family: source-han-sans-korean-medium, sans-serif;
    font-size: 14px !important;
    width: 30%;
    margin: 9px 35px 0;
    margin-left: 2.5rem !important;
}
.outbox-limit2 {
    min-width: 730px;
}

.outbox-limit3 {
    min-width: 800px;
}

.headerline-long2 {
    width: 610px;
    height: 0;
    margin: 20px 55px 3px;
    border: solid 0.5px #000;
}

.headerline-long3 {
    width: 674px;
    height: 0;
    margin: 20px 55px 3px;
    border: solid 0.5px #000;
}
.selector-single3 {
    width: 900px;
    margin: 2px 0;
    font-family: "NotoSansKR-DemiLight";
    font-size: 13px;
    margin-bottom: 10px !important;
    margin-right: 20px;
}

.selector-triple {
    width: 280px;
    margin: 2px 0 10px;
    font-family: "NotoSansKR-DemiLight";
    font-size: 13px;
    margin-right: 20px;
}

/* asterisk - if it is a required field */
.required::after {
    content: " *";
    color: red;
}

/* Modal outbox */
.outbox-limit {
    min-width: 630px;
}

.outbox-limit-weightinfo {
    min-width: 1500px;
    height: auto;
    min-height: 100%;
}

.outbox-weightinfo {
    width: 1500px;
}
.outbox-addUsers {
    width: 630px;
}

.outbox-limit-add {
    min-width: 800px;
    height: auto;
    min-height: 100%;
}

.outbox-add {
    width: 800px;
}

/* button */
.btn-save {
    width: 80px;
    height: 40px;
    margin: 20px -250px 70px 0;
    border-radius: 1.5px !important;
    color: #fff;
    background-color: #aa182c !important;
    border-color: #aa182c !important;
    font-family: source-han-sans-korean, sans-serif !important;
    font-size: small !important;
    box-shadow: none !important;
}

.btn-cancel {
    width: 80px;
    height: 40px;
    margin: 20px 210px 70px 260px;
    border-radius: 1.5px !important;
    border: solid 1px #666 !important;
    background-color: #fff !important;
    color: #000 !important;
    font-family: source-han-sans-korean, sans-serif !important;
    font-size: small !important;
    box-shadow: none !important;
}

.btn-positive {
    width: 80px;
    height: 40px;
    margin: 0 210px 20px;
    border-radius: 1.5px !important;
    color: #fff;
    background-color: #aa182c !important;
    border-color: #aa182c !important;
    font-family: source-han-sans-korean, sans-serif !important;
    font-size: small !important;
    box-shadow: none !important;
}

.btn-negative {
    width: 80px;
    height: 40px;
    margin: 0 -200px 20px;
    border-radius: 1.5px !important;
    border: solid 1px #666 !important;
    background-color: #fff !important;
    color: #000 !important;
    font-family: source-han-sans-korean, sans-serif !important;
    font-size: small !important;
    box-shadow: none !important;
}

.btn-solo {
    width: 80px;
    height: 40px;
    margin: 20px 0 30px;
    border-radius: 1.5px !important;
    color: #fff;
    background-color: #aa182c !important;
    border-color: #aa182c !important;
    font-family: source-han-sans-korean, sans-serif !important;
    font-size: small !important;
    box-shadow: none !important;
}

.btn-resetPW {
    width: 130px;
    height: 40px;
    margin: 20px 10px 70px 0;
    border-radius: 1.5px !important;
    color: #fff;
    background-color: #aa182c !important;
    border-color: #aa182c !important;
    font-family: source-han-sans-korean, sans-serif !important;
    font-size: small !important;
    box-shadow: none !important;
}

input[type="radio"]:checked:after {
    width: 16px;
    height: 16px;
    border-radius: 15px;
    top: -3px;
    left: -1px;
    position: relative;
    background-color: #f2f5fa;
    content: "";
    display: inline-block;
    /* visibility: visible; */
    border: 6px solid #aa182c;
}

textarea {
    width: 100%;
    height: 90%;
    padding: 15px;
    font-family: source-han-sans-korean, sans-serif;
    margin-left: -2px;
}

/* Modal - UpdateStatus */
.txt-title {
    width: 500px;
    height: 26px;
    margin: 30px 62px 20px;
    font-family: source-han-sans-korean, sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: normal;
    color: #222;
}
.txt-title-weightinfo {
    width: 1800px;
    height: 40px;
    margin: 30px 62px 20px;
    font-family: source-han-sans-korean, sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: normal;
    color: #222;
}

.txt-title-add {
    width: 1800px;
    height: 40px;
    margin: 30px 62px 20px;
    font-family: source-han-sans-korean, sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: normal;
    color: #222;
}

.txt-updateStatus {
    font-size: 14px;
    font-family: "NotoSansKR-DemiLight";
    margin-left: 10px;
}

.txt-explanation {
    color: rgb(141, 141, 141);
    font-size: 12px;
    font-family: source-han-sans-korean, sans-serif;
    font-style: thin;
    margin-left: 15px;
    letter-spacing: -0.6px;
}

.outbox-updateStatus {
    width: 660px;
    height: 420px;
    border-radius: 2px;
    background-color: #fff;
}

.inbox-updateStatus {
    width: 530px;
    height: 180px;
    margin: 20px 45px 40px;
    padding: 29px 50px 31px 40px;
    border-radius: 5px;
    background-color: #f2f5fa;
}

::placeholder {
    font-size: 15px;
}

/* Modal - AddUser */
.expert-positive {
    margin: 13px 5px 10px -38px !important;
}

.expert-negative {
    margin: 13px 5px 10px 50px !important;
}

.txt-expert {
    margin-top: 10px;
    margin-bottom: 13px;
    font-size: 14px;
    font-weight: bold;
}

.OOTDWrite-input-file {
    margin-top: 10px;
    padding: 6px 15px;

    border-radius: 20px;
    color: black;
    cursor: pointer;
    margin-bottom: 30px;
}
