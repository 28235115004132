/* main title style of pages */
.manage-title {
    width: 500px;
    height: 26px;
    margin: 5px 15px 40px;
    font-family: source-han-sans-korean, sans-serif;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: normal;
    color: #333;
}

/* button style when the buttons are three */
.btn-saveTriple {
    width: 80px;
    height: 40px;
    margin: 1px -35px 18px -100px;
    border-radius: 2px !important;
    color: #fff;
    background-color: #aa182c !important;
    border-color: #aa182c !important;
    font-family: source-han-sans-korean, sans-serif !important;
    font-size: 15px !important;
    box-shadow: none !important;
}

.btn-search {
    width: 80px;
    height: 40px;
    margin: 1px -35px 18px -40px;
    border-radius: 2px !important;
    font-family: source-han-sans-korean, sans-serif !important;
    background-color: #7d9bc8 !important;
    border-color: #7d9bc8 !important;
    font-size: 15px !important;
    box-shadow: none !important;
}

.btn-add {
    width: 83px;
    height: 40px;
    margin: 1px 50px 18px;
    border-radius: 2px !important;
    color: #fff;
    background-color: #aa182c !important;
    border-color: #aa182c !important;
    font-family: source-han-sans-korean, sans-serif !important;
    font-size: 15px !important;
    box-shadow: none !important;
}

.btn-add-long {
    width: 140px;
    height: 40px;
    margin: 1px 50px 18px;
    border-radius: 2px !important;
    color: #fff;
    background-color: #aa182c !important;
    border-color: #aa182c !important;
    font-family: source-han-sans-korean, sans-serif !important;
    font-size: 15px !important;
    box-shadow: none !important;
}

/* UserInfo */

/* status button  */
.btn-status {
    width: 90px;
    height: 60px;
    font-family: "NotoSansKR-DemiLight";
    background-color: rgb(168, 180, 206) !important;
    border-color: rgb(168, 180, 206) !important;
    color: rgb(61, 61, 61) !important;
    vertical-align: middle !important;
}

.btn-status_error {
    width: 90px;
    height: 60px;
    font-family: "NotoSansKR-DemiLight";
    background-color: #aa182c !important;
    border-color: #aa182c !important;
    color: rgb(235, 235, 235) !important;
    vertical-align: middle !important;
}

.btn-edit {
    width: 58px;
    height: 40px;
    border: none !important;
    text-decoration: underline !important;
    color: #666 !important;
    box-shadow: none !important;
    margin: 0 5px;
    cursor: pointer;
}

.btn-edit_error {
    width: 58px;
    height: 40px;
    border: none !important;
    text-decoration: underline !important;
    color: #d11f37 !important;
    box-shadow: none !important;
    margin: 0 5px;
    cursor: pointer;
}

.style-searchBox {
    width: auto;
    height: 140px;
    margin-bottom: 10px;
    background-color: #f5f5f5;
}
.style-searchBox2 {
    width: auto;
    height: auto;
    margin-bottom: 10px;
    background-color: #f5f5f5;
}

.style-searchBox_soloLine {
    width: auto;
    height: 70px;
    margin-bottom: 10px;
    background-color: #f5f5f5;
}

/* label in search box */
.label-search_trUp {
    font-family: "NotoSansKR-DemiLight";
    font-size: 15px;
    margin: 32px -20px 25px 35px;
    float: left;
}

.label-search_trDown {
    font-family: "NotoSansKR-DemiLight";
    font-size: 15px;
    margin: 10px -20px 15px 35px;
    float: left;
}

/* selector in searchbox but when it is three */
.selector-tripleUp {
    width: 450px;
    margin-right: -220px;
    font-family: "NotoSansKR-DemiLight";
    font-size: 15px;
    margin: 15px 6px 7px 30px;
}

.selector-tripleDown {
    width: 450px;
    margin-right: -220px;
    font-family: "NotoSansKR-DemiLight";
    font-size: 15px;
    margin: 5px 6px 10px 30px;
}

.selector-soloTr {
    width: 450px;
    margin-right: -220px;
    font-family: "NotoSansKR-DemiLight";
    font-size: 15px;
    margin: 18px 50px 10px 10px;
}

.selector-soloQuad {
    width: 340px;
    font-family: "NotoSansKR-DemiLight";
    font-size: 15px;
    margin: 18px 10px 10px 10px;
}

/* input box for choosing date */
.input-date1 {
    width: 212px !important;
    height: 37.5px;
    font-family: "NotoSansKR-DemiLight";
    font-size: 15px;
    text-align: center;
    color: #212529;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    margin-top: 15px;
    margin-left: 50px;
}

.input-date2 {
    width: 212px !important;
    height: 37px;
    font-family: "NotoSansKR-DemiLight";
    font-size: 15px;
    text-align: center;
    color: #212529;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    margin-top: 15px;
    margin-left: 5px;
}

.input-soloTr {
    display: block;
    width: 410px !important;
    padding: 0.375rem 0.75rem;
    margin: 14px 3px 5px 10px;
    font-size: 14px;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.label-soloTr {
    margin-top: 10px;
}

/* manage User pages */
.style-input_up {
    display: block;
    width: 440px !important;
    padding: 0.375rem 0.75rem;
    margin: 10px 3px 5px 50px;
    font-size: 14px;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.style-input_down {
    display: block;
    width: 440px !important;
    padding: 0.375rem 0.75rem;
    margin: 5px 3px 10px 50px;
    font-size: 14px;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.style-input:focus {
    border: 0.5px solid grey;
    outline: 0;
}

/* manage Sys pages*/
.btn-table_delete {
    width: 100%;
    height: 100%;
    font-family: "NotoSansKR-DemiLight";
    background-color: lightgrey !important;
    background-color: #aa182c !important;
    border-color: #aa182c !important;
    vertical-align: middle !important;
}

/* manage Org pages */
.selector-doubleTr {
    width: 400px !important;
    font-family: "NotoSansKR-DemiLight";
    font-size: 15px;
    margin: 5px -100px 10px 50px;
}

/* manage Stat pages */
.graph-stat {
    width: 90%;
    height: 90%;
    border: 1px solid rgb(100, 100, 100);
    margin-left: 40px;
    padding: 5px 10px 5px 5px;
}

.style-h4 {
    margin-left: 40px;
}

.btn-searchStat {
    width: 80px;
    height: 40px;
    margin: 16px 10px 18px -65px;
    border-radius: 2px !important;
    font-family: source-han-sans-korean, sans-serif !important;
    background-color: #7d9bc8 !important;
    border-color: #7d9bc8 !important;
    font-size: 15px !important;
    box-shadow: none !important;
}

.style-tHeader {
    background-color: #f7f9fa;
    font-family: source-han-sans-korean-medium, sans-serif;
    padding: 15px 0 !important;
    box-shadow: none !important;
    border-top: 2px solid #666;
    border-bottom: 2px solid #666;
}

.style-TheadHeader {
    border-top: 3px solid #666;
}

.style-td {
    width: 250px;
    height: 40px;
    padding-top: 15px;
    vertical-align: top;
}

.style-condition {
    width: 250px;
    height: 72px !important;
    margin-left: 20px;
    margin-bottom: -10px !important;
    /* background-color: #eee; */
}

/* statReason AI label*/
.labe-AI {
    width: 250px;
    height: 72px;
    margin-bottom: -10px;
}

/* menuInfo - inputBox which can be edited */
.input-flexible {
    width: 100%;
    height: 40px;
    display: block;
    font-size: 14px !important;
    color: #212529;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    text-align: center;
}
input.input-flexible::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 1; /* Firefox */
}

/* manage bulletin pages */
.form-wrap-bulletinContent {
    margin-left: 5%;
    width: 1700px !important;
    height: max-content;
    margin-top: 40px;
}

@media print {
    canvas.chart-canvas {
        min-height: 100%;
        max-width: 100%;
        max-height: 100%;
        height: auto !important;
        width: auto !important;
    }
}

@page {
    size: auto;
    margin: 15mm;
}
