.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-link {
    color: #61dafb;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

section {
    /* margin: 20px; */
    height: 100%;
}

.div-wrapper {
    display: block;
    width: 100%;
    text-align: center;
}

.div-wrapper-copyright {
    display: inline-block;
    width: 33%;
}
.wrap {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    flex-flow: column;
}
@media (min-width: 0px) and (max-width: 1920px) {
    .wrap {
        width: 1920px;
        padding-left: 40px;
        padding-right: 40px;
        display: flex;
        flex-flow: column;
    }
}

#datePickerTwoDiv .form-control {
    margin: 0px !important;
}

hr {
    margin-top: -10px !important;
}

section {
    flex: 1;
    min-height: 78vh;
    width: 100%;
}

footer {
    color: rgb(180, 180, 180);
    /* position: absolute; */
    padding: 0.1rem;
    text-align: center;
    flex: 0;
}

footer .logo {
    width: 30%;
    height: 100%;
}

.brand-logo {
    width: 211px;
    min-height: 30px;
    max-height: 80px;
    flex-shrink: 0;
    margin-bottom: 20px;
}

.brand-title {
    margin-left: 30px;
    letter-spacing: 0.15em;
    font-weight: bolder;
    font-family: "굴림", 돋움, 맑은 고딕;
}

.container-dd {
    justify-self: space-around;
    font-family: "NotoSansKR-demilight";
    font-size: 21px;
    margin-top: 10px;
    margin-left: -90px !important;
}

#nav-dropdown {
    color: rgb(228, 228, 228);
    font-weight: lighter;
    background-color: white;
}
