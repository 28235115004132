* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.headline-signUp {
    width: 540px;
    height: 0;
    margin: 20px 40px 20px 35px;
    border: solid 0.5px #000;
}

.form-wrap-signUp {
    margin-left: 33%;
    width: 630px !important;
    height: max-content;
    margin-top: 40px;
    border-style: ridge;
    padding-bottom: 10px;
}

.div-wrap-btn {
    width: 550px;
    display: inline-block;
    margin-top: 20px;
    margin-left: 15px;
}

.btn-register {
    width: 260px;
    height: 50px;
    background-color: #aa182c !important;
    border-color: #aa182c !important;
    color: white;
    margin-left: 10px;
}

.btn-registerNo {
    width: 260px;
    height: 50px;
    margin-left: 10px;
    border: solid 1px grey !important;
    background-color: grey !important;
    color: #fff !important;
}

.div-wrap-policy {
    text-align: center;
    margin-top: 10px;
}

.btn-policy {
    font-family: "NotoSansKR-Demilight";
    font-size: 14px;
    margin-bottom: 4px;
    box-shadow: none !important;
    outline: none !important;
    float: right;
}

.txt-policy {
    color: black;
    font-family: "NotoSansKR-Bold";
    font-size: 15px;
    margin-left: 5px;
    text-align: right;
}

.style-ul {
    margin-left: 20px;
}

.style-li {
    margin-bottom: 18px;
}

.form-wrap {
    margin-left: 33%;
    width: 33%;
    margin-top: 1%;
    border: 2.5px solid gray;
    border-radius: 5px;
    padding: 30px;
}

.form-group {
    width: 560px;
    margin-left: 10px;
}

.form-group > label {
    margin-right: 20px;
}

.form-control {
    width: 550px;
    margin-top: 5px;
    margin-bottom: 15px;
    margin-left: 7px;
}

.selector-single {
    margin-left: 4px;
}
.selector-single2 {
    margin-left: 4px;
}

.form-group-wrap {
    text-align: left;
}
.form-group-wrap-content-text {
    width: 75%;
    display: inline-block;
    margin-right: 5%;
}
.form-group-wrap-content-text > span {
    width: 75%;
    display: inline-block;
    text-align: center;
    margin-right: 5%;
}

.form-group-wrap-content-btn {
    width: 20%;
    display: inline-block;
}
.btn-login-check {
    padding-top: 20px;
    margin-top: 2%;
    float: right;
}

/* 

// policy buttons 

.btn-useTerm {
  width: 15%;
  margin: 0 2%;
}

.btn-copyright {
  width: 33%;
  margin: 0 2%;
}

.btn-perinfo {
  width: 10%;
  margin: 0 2%;
}

*/
