.slider-h3 {
    color: #3498db;
    text-align: center;
}

.slick-prev:before {
    opacity: 1;
    color: black;
}
.slick-next:before {
    opacity: 1;
    color: black;
}
