.flex {
    display: flex;
}
/* 
.scroll {
    width: 100px;
    padding: 0px 13px 0px 13px;
    overflow-y: scroll;
    height: 200px;
    box-sizing: border-box;
    color: white;
    font-family: "Nanum Gothic";
    background-color: rgba(0, 0, 0, 0.8);
    margin-right: 50px;
} */

/* 스크롤바 설정*/
.type1::-webkit-scrollbar {
    width: 6px;
}

/* 스크롤바 막대 설정*/
.type1::-webkit-scrollbar-thumb {
    height: 17%;
    background-color: rgba(255, 255, 255, 1);
    /* 스크롤바 둥글게 설정    */
    border-radius: 10px;
}

/* 스크롤바 뒷 배경 설정*/
.type1::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
}

/* 스크롤바 설정*/
.type2::-webkit-scrollbar {
    width: 6px;
}

/* 스크롤바 막대 설정*/
.type2::-webkit-scrollbar-thumb {
    height: 17%;
    background-color: rgba(33, 133, 133, 1);
    border-radius: 10px;
}

/* 스크롤바 뒷 배경 설정*/
.type2::-webkit-scrollbar-track {
    background-color: rgba(33, 133, 133, 0.33);
}

/* ::-webkit-scrollbar {
    width: 15px;
}

::-webkit-scrollbar-track {
    background: #eeeeee;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb {
    margin: 10px;
    background: #c2e59c;
    background: linear-gradient(#c2e59c, #64b3f4); 
    border-radius: 5px;
} */

.scrollDiv {
    /* width: 200px; */
    /* height: 400px; */
    /* overflow: scroll; */
    /* background-color: #1e1e1e; */
    /* color: white; */
    padding: 5px;
}

.scrollDiv::-webkit-scrollbar {
    width: 15px; /*스크롤바의 너비*/
}

.scrollDiv::-webkit-scrollbar-thumb {
    background-color: rgba(66, 66, 66, 0.3); /*스크롤바의 색상*/
    background-clip: padding-box;
    border: 4px solid transparent;
    border-radius: 10px;
    /* border-top-left-radius: 50px; */
    /* border-bottom-right-radius: 50px; */
}

.scrollDiv::-webkit-scrollbar-track {
    /*스크롤바 트랙 색상*/
    /* background-color: grey; */
}
